//Imports

const Merch = () => {
  //Hooks

  //Functions

  return (
    <>
      <h1>Happy Hacking!</h1>
    </>
  );
};

export default Merch;
